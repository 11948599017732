import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['spinerWrapper']

  static values = {
    path: String
  }

  connect() {
    this.beforeSpinElement()

    setTimeout(() => {
      this.load()
    }, 15000)
  }

  load() {
    this.spinElement()

    fetch(this.pathValue)
      .then((response) => {
        switch (response.status) {
          case 200:
            this.render(response.text());
            break;
          case 204:
            this.render('');
            break;
          default:
            break;
        }
      }
    )
  }

  beforeSpinElement() {
    this.spinerWrapperTarget.innerHTML = '<i class="fas fa-sync" />'
  }

  spinElement() {
    this.spinerWrapperTarget.innerHTML = '<i class="fas fa-sync fa-spin" />'
  }

  async render(html) {
    this.element.innerHTML = ''
    this.element.insertAdjacentHTML('afterbegin', await html)
  }
}
